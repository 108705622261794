exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/Header/Header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

